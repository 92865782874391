$(function() {

    $('#dataTableRegions').DataTable({
        processing: true,
        serverSide: true,
        serverMethod: 'post',
        autoWidth: false,
        ajax: {
            url: 'regions/get',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            },
        },
        order: [[0, 'desc']],
        columns: [
            { data: 'id' },
            { data: 'type' },
            { data: 'url' },
            {
                data: 'translation',
                render: function ( data, type, row ) {
                    if (!row.translation_uk) {
                        return '';
                    }
                    return row.translation_uk.name;
                },
                orderable: false
            },
        ],
        "aLengthMenu": [
            [10, 30, 50],
            [10, 30, 50]
        ],
        "iDisplayLength": 10,
        "language": {
            url: '/js/admin/translation/datatables-uk.json'
        }
    });
});
